import React from 'react';

import css from './Radio.module.css';

interface IRadioProps extends React.InputHTMLAttributes<HTMLInputElement> {
  outline?: boolean;
  alignStart?: boolean;
  'data-testid'?: string;
}

const Radio = React.forwardRef<HTMLInputElement, IRadioProps>(
  (
    {
      className = '',
      children,
      disabled,
      outline,
      ['data-testid']: testId = 'radio-wrapper',
      ...props
    },
    ref,
  ) => (
    <label
      data-testid={testId}
      className={`flex relative justify-items-center cursor-pointer items-center ${css.label} ${
        outline ? css.outline : ''
      }
        ${props.checked && outline ? css.active : ''}
        ${className}
      `}
      data-disabled={disabled}>
      <input
        data-testid="radio-input"
        type="radio"
        className={`shrink-0 disabled:cursor-default cursor-pointer opacity-0 mr-3 ${css.input}`}
        disabled={disabled}
        ref={ref}
        {...props}
      />
      <span
        className={`pointer-events-none absolute grid justify-items-center items-center rounded-full border-2 border-solid border-gray-500 text-gray-500 duration-150 ease-in-out transition-colors ${css.control}`}
      />
      {children}
    </label>
  ),
);

Radio.displayName = 'Radio';

export default Radio;
