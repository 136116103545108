import {
  Accordion,
  AlignType,
  Button,
  EButtonColorVariant,
  EHeadingStyleVariant,
  ELinkVariant,
  EModalHeaderTextAlignVariant,
  EModalSize,
  EModalVariants,
  ESelectorVariants,
  ETextStyleVariant,
  Heading,
  Icon,
  Link,
  LinkButton,
  Modal as BonfireModal,
  Selector,
  Text,
} from '@outdoorsyco/bonfire';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import IMGLogoSVG from '@/assets/protection/img/img-logo.svg';
import RoamlyWeatherLogoSVG from '@/assets/protection/roamly-weather/roamly-weather-logo.svg';
import Divider from '@/components/switchback/Divider/Divider';
import Modal from '@/components/switchback/Modal';
import Radio from '@/components/switchback/Radio/Radio';
import { useToast } from '@/components/switchback/Toast/ToastProvider';
import { ProtectionDialogContent } from '@/components/ui/TripProtection/ProtectionDialogContent';
import { EProtectionLabel, ProtectionLabel } from '@/components/ui/TripProtection/ProtectionLabel';
import { ServiceIntegration } from '@/graphql/types-and-hooks';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { addBookingService, getBooking, removeBookingService } from '@/redux/modules/checkout';
import { getQuote } from '@/redux/modules/quote';
import { getBookingOrQuote } from '@/redux/selectors/bill/data';
import { getBookingId } from '@/redux/selectors/checkout/booking';
import { GENERIC_ERROR_MESSAGE } from '@/services/apiRequest';
import { OptimizelyFlags, useExperimentIsEnabled } from '@/services/experiments';
import { IBookingService } from '@/services/types/booking/services';
import { EProtections } from '@/services/types/core/protections';
import { formatCurrency } from '@/utility/currency';
import { useThunkDispatch } from '@/utility/redux/dispatch';

interface IProtectionModalProps {
  isOpened: boolean;
  onClose: () => void;
  availableTripInsuranceService?: ServiceIntegration;
  addedTripInsuranceService?: IBookingService;
  availableRoamlyWeatherInsuranceService?: ServiceIntegration;
  addedRoamlyWeatherInsuranceService?: IBookingService;
  tripInsuranceServiceIsAdded?: boolean;
  weatherInsuranceServiceIsAdded?: boolean;
  onSubmit?: () => void;
  confirmLabel?: string;
  rentalId?: number;
  isCampsite?: boolean;
}

export const ProtectionModal: React.FC<IProtectionModalProps> = ({
  isOpened,
  onClose,
  availableTripInsuranceService,
  addedTripInsuranceService,
  availableRoamlyWeatherInsuranceService,
  addedRoamlyWeatherInsuranceService,
  tripInsuranceServiceIsAdded,
  weatherInsuranceServiceIsAdded,
  onSubmit,
  confirmLabel,
  rentalId,
  isCampsite = false,
}) => {
  const intl = useIntl();
  const weatherInsuranceExperimentDecision = useExperimentIsEnabled(
    OptimizelyFlags.ROAMLY_WEATHER_PRE_PURCHASE,
  );
  const { isMobile } = useBreakpoint();
  const { addToast } = useToast();
  const dispatch = useThunkDispatch();
  const bookingOrQuote = useSelector(getBookingOrQuote);
  const bookingId = useSelector(getBookingId);
  const [submittingProtectionChange, setSubmittingProtectionChange] = useState(false);
  const [submittingWeatherProtectionChange, setSubmittingWeatherProtectionChange] = useState(false);
  const [addTripProtection, setAddTripProtection] = useState<boolean | undefined>(
    tripInsuranceServiceIsAdded,
  );
  const [addWeatherProtection, setAddWeatherProtection] = useState<boolean | undefined>(
    weatherInsuranceServiceIsAdded,
  );
  const [decliningProtection, setDecliningProtection] = useState(false);
  const [openSections, setOpenSections] = useState<{ [key: string]: string[] }>({
    trip: [],
    weather: [],
  });

  let hasProtectionInQuoteError = false;
  let hasWeatherProtectionInQuoteError = false;

  const tripInsurancePrice = formatCurrency({
    priceInCents: (addedTripInsuranceService || availableTripInsuranceService)?.total || 0,
    currency: bookingOrQuote?.presentment_currency || 'USD',
    digits: 2,
  });

  const weatherInsurancePrice =
    (availableRoamlyWeatherInsuranceService?.roamly_weather_quote?.plain_language?.details
      ?.suggested_price || '') +
    ', ' +
    (availableRoamlyWeatherInsuranceService?.roamly_weather_quote?.plain_language?.details
      ?.suggested_price_total || '');
  const weatherInsuranceDescription =
    availableRoamlyWeatherInsuranceService?.roamly_weather_quote?.plain_language?.main?.body || '';
  const weatherSummary =
    availableRoamlyWeatherInsuranceService?.roamly_weather_quote?.plain_language?.details
      ?.summary_body || '';
  const weatherSummaryTiers =
    availableRoamlyWeatherInsuranceService?.roamly_weather_quote?.plain_language?.details
      ?.summary_tiers || [];

  const learnMoreTitle = intl.formatMessage({ defaultMessage: 'Learn more', id: 'TdTXXf' });

  const onOpenSectionsChange = (protection: 'trip' | 'weather') => {
    setOpenSections(prev => {
      const currentSections = prev ?? { trip: [], weather: [] };
      return {
        ...currentSections,
        [protection]: currentSections[protection]?.length === 0 ? [learnMoreTitle] : [],
      };
    });
  };

  const onProtectionChange = (protection: 'trip' | 'weather') => {
    if (protection === 'trip') {
      setAddTripProtection(!addTripProtection);
      if (!addTripProtection) {
        setOpenSections(prev => ({ ...prev, trip: [learnMoreTitle] }));
      }
    } else {
      setAddWeatherProtection(!addWeatherProtection);
      if (!addWeatherProtection) {
        setOpenSections(prev => ({ ...prev, weather: [learnMoreTitle] }));
      }
    }
  };

  const toggleProtectionInQuote = async () => {
    if (!availableTripInsuranceService?.id) return;

    try {
      await dispatch(
        getQuote({
          serviceId: availableTripInsuranceService.id,
          serviceShouldBeAdded: addTripProtection,
          throwOnFetchFailure: true,
          rentalId,
        }),
      );
    } catch {
      hasProtectionInQuoteError = true;
      addToast({
        title: intl.formatMessage({ defaultMessage: 'Oops!', id: 'BEbOqj' }),
        description: GENERIC_ERROR_MESSAGE,
        type: 'TOAST_ERROR',
      });
    }
  };

  const toggleWeatherProtectionInQuote = async () => {
    if (!availableRoamlyWeatherInsuranceService?.id) return;

    try {
      await dispatch(
        getQuote({
          serviceId: availableRoamlyWeatherInsuranceService.id,
          serviceShouldBeAdded: addWeatherProtection,
          throwOnFetchFailure: true,
          rentalId,
        }),
      );
    } catch {
      hasWeatherProtectionInQuoteError = true;
      addToast({
        title: intl.formatMessage({ defaultMessage: 'Oops!', id: 'BEbOqj' }),
        description: GENERIC_ERROR_MESSAGE,
        type: 'TOAST_ERROR',
      });
    }
  };

  const toggleProtectionInBooking = async () => {
    if (!bookingId) return;

    try {
      if (addTripProtection) {
        if (availableTripInsuranceService?.id) {
          await dispatch(addBookingService(bookingId, availableTripInsuranceService.id));
        }

        return;
      }

      if (addedTripInsuranceService?.service_id) {
        await dispatch(removeBookingService(bookingId, addedTripInsuranceService.service_id));
      }
    } catch {
      addToast({
        title: intl.formatMessage({ defaultMessage: 'Oops!', id: 'BEbOqj' }),
        description: intl.formatMessage({
          defaultMessage: 'Your recent change is still processing. Try again in a minute or so.',
          id: 'Kk5UWs',
        }),
        type: 'TOAST_ERROR',
      });
    }
  };

  const toggleWeatherProtectionInBooking = async () => {
    if (!bookingId) return;

    try {
      if (addWeatherProtection) {
        if (availableRoamlyWeatherInsuranceService?.id) {
          const weatherQuote = availableRoamlyWeatherInsuranceService.roamly_weather_quote || {};
          await dispatch(
            addBookingService(bookingId, availableRoamlyWeatherInsuranceService.id, weatherQuote),
          );
        }

        return;
      }

      if (addedRoamlyWeatherInsuranceService?.service_id) {
        await dispatch(
          removeBookingService(bookingId, addedRoamlyWeatherInsuranceService.service_id),
        );
      }
    } catch {
      addToast({
        title: intl.formatMessage({ defaultMessage: 'Oops!', id: 'BEbOqj' }),
        description: intl.formatMessage({
          defaultMessage: 'Your recent change is still processing. Try again in a minute or so.',
          id: 'Kk5UWs',
        }),
        type: 'TOAST_ERROR',
      });
    }
  };

  const handleConfirmProtectionChange = async () => {
    try {
      setSubmittingProtectionChange(true);
      setSubmittingWeatherProtectionChange(true);

      // Toggle protection only if there are any actual changes.
      if (addTripProtection === !addedTripInsuranceService) {
        if (bookingId) {
          await toggleProtectionInBooking();
          await dispatch(getBooking(bookingId));
        } else {
          await toggleProtectionInQuote();
        }
      }

      if (addWeatherProtection === !addedRoamlyWeatherInsuranceService) {
        if (bookingId) {
          await toggleWeatherProtectionInBooking();
          await dispatch(getBooking(bookingId));
        } else {
          await toggleWeatherProtectionInQuote();
        }
      }
    } finally {
      setSubmittingProtectionChange(false);
      setSubmittingWeatherProtectionChange(false);
    }
    if (!hasProtectionInQuoteError && !hasWeatherProtectionInQuoteError && isCampsite) {
      hasProtectionInQuoteError = false;
      hasWeatherProtectionInQuoteError = false;
      onSubmit?.();
      return;
    }
    onSubmit?.();
    onClose();
  };

  useEffect(() => {
    if (addTripProtection === false || addWeatherProtection === false) {
      handleConfirmProtectionChange();
      setDecliningProtection(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decliningProtection]);

  const onDeclineProtection = () => {
    setAddTripProtection(false);
    setAddWeatherProtection(false);
    setDecliningProtection(true);
    onClose();
  };

  return (
    <>
      {weatherInsuranceExperimentDecision ? (
        <BonfireModal
          isOpen={isOpened}
          onClose={onClose}
          size={EModalSize.Medium}
          variant={isMobile ? EModalVariants.Bottom : undefined}>
          <BonfireModal.Actions />
          <BonfireModal.Header textAlign={EModalHeaderTextAlignVariant.Left} inlineHeader>
            <Heading variant={EHeadingStyleVariant.H5}>
              <FormattedMessage defaultMessage="Protect your trip" id="903/wm" />
            </Heading>
          </BonfireModal.Header>
          <BonfireModal.Content className="flex gap-6 max-h-[560px]">
            <Text variant={ETextStyleVariant.SmallRegular} className="text-gray-50">
              <FormattedMessage
                defaultMessage="Available to U.S. residents only. Add or cancel protection until the day before your trip. "
                id="nEtGHL"
              />
            </Text>
            {availableTripInsuranceService && (
              <div>
                <Selector
                  isSelected={addTripProtection === true}
                  variant={ESelectorVariants.Checkbox}
                  alignVariant={AlignType.VerticalLeft}
                  ariaLabel={intl.formatMessage({
                    defaultMessage: 'Trip insurance selector',
                    id: 'MLysAH',
                  })}
                  icon="General.Cancel"
                  label={intl.formatMessage(
                    {
                      defaultMessage: 'Cancellation & more for {tripInsurancePrice}',
                      id: 'biFpDk',
                    },
                    { tripInsurancePrice },
                  )}
                  name="add-trip-protection"
                  onChange={() => onProtectionChange('trip')}
                  description={intl.formatMessage({
                    defaultMessage:
                      'Even the best-planned trips can be affected by an unexpected event. Get reimbursed up to 100% due to a covered reason.',
                    id: 'gB+a2d',
                  })}
                  value="add-trip-protection"
                />
                <Accordion
                  className="w-full"
                  openSections={openSections.trip}
                  onOpenSectionsChange={() => onOpenSectionsChange('trip')}>
                  <Accordion.Item divider title={learnMoreTitle} className="p-0">
                    <div className="flex flex-col gap-4">
                      <div className="flex items-center justify-center w-full bg-[#2C415F] gap-2">
                        <Text variant={ETextStyleVariant.LegalRegular} className="text-white">
                          <FormattedMessage defaultMessage="Powered by" id="U8QBHO" />
                        </Text>
                        <IMGLogoSVG height={88} />
                      </div>
                      <div className="flex gap-2">
                        <div className="pt-1">
                          <Icon name="General.Check.Circle" className="text-green-500" />
                        </div>
                        <Text className="text-gray-800">
                          <FormattedMessage
                            defaultMessage="Get reimbursed up to 100% of the non-refundable costs of your booking, in case of a trip cancellation or interruption due to a covered reason."
                            id="rvD2mE"
                          />
                        </Text>
                      </div>
                      <div className="flex gap-2">
                        <div className="pt-1">
                          <Icon name="General.Check.Circle" className="text-green-500" />
                        </div>
                        <Text className="text-gray-800">
                          <FormattedMessage
                            defaultMessage="Get reimbursed for the purchase of personal items, if baggage is delayed 24 hours or more."
                            id="YtTmvm"
                          />
                        </Text>
                      </div>
                      <div className="flex gap-2">
                        <div className="pt-1">
                          <Icon name="General.Check.Circle" className="text-green-500" />
                        </div>
                        <Text className="text-gray-800">
                          <FormattedMessage
                            defaultMessage="24/7 emergency transportation to get you to a medical facility when you need care right away."
                            id="P2P0ul"
                          />
                        </Text>
                      </div>
                      <div>
                        <Link
                          variant={ELinkVariant.Underline}
                          href="/help/travel-insurance-work"
                          target="_blank">
                          <Text className="text-gray-800">
                            <FormattedMessage
                              defaultMessage="Learn more about Trip protection"
                              id="ShtHr3"
                            />
                          </Text>
                        </Link>
                      </div>
                      <div className="pb-2">
                        <Text variant={ETextStyleVariant.SmallRegular}>
                          <FormattedMessage
                            defaultMessage="Available to U.S. residents only. Add cancellation protection until the day of your trip. Cancel within 10 days after purchase."
                            id="sCZCu+"
                          />
                        </Text>
                      </div>
                    </div>
                  </Accordion.Item>
                </Accordion>
              </div>
            )}

            {availableRoamlyWeatherInsuranceService && (
              <div>
                <Selector
                  isSelected={addWeatherProtection === true}
                  variant={ESelectorVariants.Checkbox}
                  alignVariant={AlignType.VerticalLeft}
                  ariaLabel={intl.formatMessage({
                    defaultMessage: 'Weather insurance selector',
                    id: 'ar9C0q',
                  })}
                  icon="General.Umbrella"
                  label={weatherInsurancePrice}
                  name="add-weather-protection"
                  onChange={() => onProtectionChange('weather')}
                  description={weatherInsuranceDescription}
                  value="add-weather-protection"
                />
                <Accordion
                  className="w-full"
                  openSections={openSections.weather}
                  onOpenSectionsChange={() => onOpenSectionsChange('weather')}>
                  <Accordion.Item divider title={learnMoreTitle} className="p-0">
                    <div className="flex flex-col gap-4">
                      <div className="flex items-center justify-center w-full bg-[#081140] gap-2">
                        <Text variant={ETextStyleVariant.LegalRegular} className="text-white">
                          <FormattedMessage defaultMessage="Powered by" id="U8QBHO" />
                        </Text>
                        <RoamlyWeatherLogoSVG height={88} />
                      </div>
                      {weatherSummaryTiers.length > 0 && (
                        <div>
                          <Text className="text-gray-800">{weatherSummary}</Text>
                          <ul className="mt-2 ml-5 text-gray-800 list-disc">
                            {weatherSummaryTiers.map((tier, index) => (
                              <li key={index}>{tier}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                      <div>
                        <Link
                          variant={ELinkVariant.Underline}
                          href="https://www.sensibleweather.com/sensible-weather-privacy-policy"
                          target="_blank">
                          <Text className="text-gray-800">
                            <FormattedMessage
                              defaultMessage="View Sensible's Privacy Policy"
                              id="ypuy0h"
                            />
                          </Text>
                        </Link>
                      </div>
                      <div>
                        <Link
                          variant={ELinkVariant.Underline}
                          href="https://www.sensibleweather.com/guarantee-terms-and-conditions"
                          target="_blank">
                          <Text className="text-gray-800">
                            <FormattedMessage
                              defaultMessage="View Sensible's Terms and Conditions"
                              id="EC6xk3"
                            />
                          </Text>
                        </Link>
                      </div>
                      <div className="pb-2">
                        <Text variant={ETextStyleVariant.SmallRegular}>
                          <FormattedMessage
                            defaultMessage="Available to U.S. residents only. Add weather protection until the day before your trip. Cancel within 24 hours after purchase."
                            id="tgrtji"
                          />
                        </Text>
                      </div>
                    </div>
                  </Accordion.Item>
                </Accordion>
              </div>
            )}
          </BonfireModal.Content>
          <BonfireModal.Divider className="mt-10" />
          <BonfireModal.Footer>
            <div className="flex items-center justify-between w-full">
              <LinkButton
                label={intl.formatMessage({ defaultMessage: 'Decline protection', id: '9BS4eG' })}
                onClick={onDeclineProtection}
              />
              <Button
                label={
                  confirmLabel
                    ? confirmLabel
                    : intl.formatMessage({ defaultMessage: 'Confirm', id: 'N2IrpM' })
                }
                disabled={
                  submittingProtectionChange ||
                  submittingWeatherProtectionChange ||
                  (addTripProtection === undefined && addWeatherProtection === undefined)
                }
                loading={submittingProtectionChange || submittingWeatherProtectionChange}
                onClick={handleConfirmProtectionChange}
                variant={EButtonColorVariant.Primary}
              />
            </div>
          </BonfireModal.Footer>
        </BonfireModal>
      ) : (
        <Modal
          open={isOpened}
          onClose={onClose}
          title={
            <div className="text-lg text-gray-800 highlight">
              <ProtectionLabel
                category={EProtections.TRIP_INSURANCE}
                label={EProtectionLabel.TITLE}
              />
            </div>
          }
          footer={
            <>
              <Divider className="block mb-6" />

              <div className="flex items-center justify-end">
                <Button
                  className="w-auto"
                  variant={EButtonColorVariant.Primary}
                  label={
                    confirmLabel
                      ? confirmLabel
                      : intl.formatMessage({ defaultMessage: 'Confirm', id: 'N2IrpM' })
                  }
                  disabled={submittingProtectionChange || addTripProtection === undefined}
                  loading={submittingProtectionChange}
                  onClick={handleConfirmProtectionChange}
                />
              </div>
            </>
          }>
          <ProtectionDialogContent
            category={EProtections.TRIP_INSURANCE}
            serviceIsAdded
            inBillSection>
            <div className="flex flex-col md:flex-row">
              <Radio
                checked={addTripProtection === true}
                name="add-trip-protection"
                onChange={() => setAddTripProtection(true)}
                value="yes"
                className="flex-1 mb-2 md:mr-4 md:mb-0"
                outline>
                <p className="semiHighlight">
                  <FormattedMessage
                    defaultMessage="Yes, add trip protection for {price}"
                    id="5OtYHY"
                    values={{ price: tripInsurancePrice }}
                  />
                </p>
              </Radio>

              <Radio
                checked={addTripProtection === false}
                name="decline-trip-protection"
                onChange={() => setAddTripProtection(false)}
                value="no"
                className="flex-1"
                outline>
                <p className="semiHighlight">
                  <FormattedMessage
                    defaultMessage="No, I choose to decline this protection"
                    id="tl5/LK"
                  />
                </p>
              </Radio>
            </div>
          </ProtectionDialogContent>
        </Modal>
      )}
    </>
  );
};
